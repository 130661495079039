import api from "./api";

const uploadFilesApi = async (files, endpoint = "upload/image") => {
  try {
    const fileList = Array.isArray(files) ? files : [files];
    if (fileList.length === 0) {
      throw new Error("No files provided");
    }
    if (!endpoint) {
      throw new Error("Endpoint is required");
    }
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files", file);
    });
    const response = await api.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.error(`Failed to upload files: ${error.message}`);
    throw error;
  }
};
export default uploadFilesApi;