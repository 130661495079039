import api from './api'

const url = 'candidates'

const createCandidate = (data) => api.post(`${url}/create`, data)

const updateCandidate = id => api.put(`${url}/?id${id}`)

const findAllCandidates = () => api.get(`${url}/`)

const getCandidateById = (id) => api.get(`${url}/?id${id}`)


export default { createCandidate, updateCandidate, findAllCandidates, getCandidateById }
