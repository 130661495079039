<template>
  <v-container>
    <v-row class="py-10 px-md-15" align="center" justify="start">
      <v-col cols="12" sm="12" md="8">
        <session-title
          title="Candidatar-se"
          description="Encontre a oportunidade perfeita para a sua carreira. Preencha seus dados e dê o próximo passo rumo ao sucesso profissional!"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" class="text-right">
        <chat-ti-modal-access-vue />
      </v-col>

      <v-col cols="6" sm="6" md="2" class="mb-4 pt-6">
        <v-text-field
          v-model="candidate.jobId"
          label="Vaga ID"
          disabled
          dense
          readonly
        />
      </v-col>

      <v-col cols="6" sm="6" md="4" class="mb-4 pt-6">
        <v-text-field v-model="jobTitle" label="Vaga" disabled dense readonly />
      </v-col>

      <v-col cols="12" sm="12" md="6" class="mb-4 pt-6">
        <v-text-field
          v-model="candidate.name"
          label="Nome"
          dense
          disabled
          readonly
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mb-4 pt-6">
        <v-text-field
          v-model="contactData.email"
          label="Email"
          dense
          disabled
          readonly
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mb-4 pt-6">
        <v-text-field
          v-model="contactData.phone"
          label="Phone"
          dense
          disabled
          readonly
        />
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-file-input
          v-model="files"
          show-size
          counter
          type="file"
          label="Carregar currículo .pdf"
          accept=".pdf"
          @change="uploadFiles"
        />
      </v-col>

      <v-col cols="12" sm="12" align="center" class="mt-10 px-5">
        <v-btn
          color="secondary"
          elevation="2"
          large
          :disabled="!formValid"
          @click="sendCandidature"
        >
          Enviar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SessionTitle from "@/components/SessionTitle";
import ChatTiModalAccessVue from "@/components/ModalViews/ChatTiModalAccess.vue";
import Collaborator from "../../services/collaborator-service";
import Candidates from "../../services/candidates-service";
import uploadFilesApi from "../../services/upload-service";

export default {
  name: "JobApplicationForm",
  components: {
    SessionTitle,
    ChatTiModalAccessVue,
  },

  data() {
    return {
      candidate: {
        name: "",
        jobId: "",
        contactId: "",
        curriculo: "",
      },

      contactData: {
        id: "",
        user: "",
        name: "",
        email: "",
        phone: "",
        numCad: "",
        yearUpdated: "",
      },

      userAd: this.$store.getters["auth/userAd"],
      jobTitle: "",
      loading: false,

      files: null,
      formValid: false,
    };
  },

  watch: {
    candidate: {
      handler: async function () {
        this.validatedForm();
      },
    },
    deep: true,
  },

  async created() {
    await this.initComponent();
  },

  methods: {
    validatedForm() {
      this.formValid =
        this.candidate.name &&
        this.candidate.jobId &&
        this.candidate.contactId &&
        this.candidate.curriculo;
    },

    async initComponent() {
      try {
        await this.loadContactInfo();
        await this.jobData();
      } catch (error) {
        console.error("Erro ao inicializar o componente:", error.message);
      }
    },

    async loadContactInfo() {
      const contactData = await Collaborator.getContact(this.userAd);
      this.candidate = {
        name: contactData.name,
        contactId: contactData.id,
      };

      this.contactData = {
        email: contactData.email,
        phone: contactData.phone,
      };
    },

    async sendCandidature() {
      this.validatedForm();
      if (!this.formValid) {
        this.$toast.error(
          "Erro ao enviar candidatura, verifique os campos obrigatórios!"
        );
        return;
      }

      this.loading = true;
      try {
        const response = await Candidates.createCandidate(this.candidate);

        if (response) {
          this.$toast.success("Candidatura enviada com sucesso!", {
            onComplete: this.$router.go(-1),
          });

        } else {
          console.error("Resposta inesperada ao enviar candidatura:", response);
          this.$toast.error("Algo deu errado. Por favor, tente novamente.");
        }
      } catch (error) {
        console.error("Erro ao enviar candidatura:", error.message);
        this.$toast.error(
          "Falha ao enviar candidatura. Tente novamente mais tarde."
        );
      } finally {
        this.loading = false;
      }
    },

    async jobData() {
      const jobData = await this.$store.state.candidate.job;
      this.candidate.jobId = jobData.id;
      this.jobTitle = jobData.title;
    },

    async uploadFiles() {
      if (!this.files) {
        this.$toast.error("Nenhum arquivo PDF selecionado");
        return;
      }
      try {
        const response = await uploadFilesApi(this.files);
        if (Array.isArray(response) && response.length > 0) {
          this.candidate.curriculo = response[0];
          this.$toast.success("O PDF foi carregado com sucesso!");
          this.validatedForm();
        } else {
          throw new Error("No URLs returned from the upload service");
        }
      } catch (error) {
        console.error("Erro durante o upload do arquivo:", error.message);
        this.$toast.error("Não foi possível carregar o PDF!");
      }
    },
  },
};
</script>
